var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.headers,"items":_vm.table.items,"options":_vm.tableOptions,"server-items-length":_vm.table.totalItems,"loading":_vm.table.loading},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.institusi",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nama_institusi)+"/ "+_vm._s(item.nama_prodi)+" ")]}},{key:"item.stase",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nama_stase)+", "+_vm._s(item.stase)+" ")]}},{key:"item.tanggal_awal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.tanggal_awal))+" ")]}},{key:"item.tanggal_akhir",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.tanggal_akhir))+" ")]}},{key:"item.flag_end",fn:function(ref){
var item = ref.item;
return [(item.flag_end == 0)?_c('span',[_vm._v("Aktif")]):_c('span',[_vm._v("Non Aktif")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'UniversitasPraktikAnggota', params: { id: item.id } }}},[_c('v-list-item-title',[_vm._v("Daftar Anggota")])],1),_c('router-link',{attrs:{"to":{ name: 'UniversitasDaftar', params: { praktik_id: item.id } }}},[_c('v-list-item-title',[_vm._v("Daftar Hadir")])],1),(item.profesi == 'Dokter')?_c('router-link',{attrs:{"to":{ name: 'UniversitasEvaluasiDokter', params: { praktik_id: item.id } }}},[_c('v-list-item-title',[_vm._v("Evaluasi Peserta Didik")])],1):(item.profesi == 'PPDS')?_c('router-link',{attrs:{"to":{ name: 'UniversitasEvaluasiPPDS', params: { praktik_id: item.id } }}},[_c('v-list-item-title',[_vm._v("Evaluasi Peserta Didik")])],1):(item.profesi == 'Keperawatan,Kebidanan, dan Tenaga Kesehatan Lain')?_c('router-link',{attrs:{"to":{ name: 'UniversitasEvaluasiLain', params: { praktik_id: item.id } }}},[_c('v-list-item-title',[_vm._v("Evaluasi Peserta Didik")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getDataFromApi}},[_vm._v(" Reload ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }