<template>
	<v-card flat class="">
		<v-data-table :headers="table.headers" :items="table.items" :options.sync="tableOptions"
			:server-items-length="table.totalItems" :loading="table.loading" class="elevation-1">
			<template v-slot:item.institusi="{ item }">
				{{ item.nama_institusi }}/ {{ item.nama_prodi }}
			</template>
			<template v-slot:item.stase="{ item }">
				{{ item.nama_stase }}, {{ item.stase }}
			</template>
			<template v-slot:item.tanggal_awal="{ item }">
				{{ formatDate(item.tanggal_awal) }}
			</template>
			<template v-slot:item.tanggal_akhir="{ item }">
				{{ formatDate(item.tanggal_akhir) }}
			</template>
			<template v-slot:item.flag_end="{ item }">
				<span v-if="item.flag_end == 0">Aktif</span>
				<span v-else>Non Aktif</span>
			</template>
			<template v-slot:item.actions="{ item }">
				<router-link :to="{ name: 'UniversitasPraktikAnggota', params: { id: item.id } }">
					<v-list-item-title>Daftar Anggota</v-list-item-title>
				</router-link>
				<router-link :to="{ name: 'UniversitasDaftar', params: { praktik_id: item.id } }">
					<v-list-item-title>Daftar Hadir</v-list-item-title>
				</router-link>
				<router-link v-if="item.profesi == 'Dokter'"
					:to="{ name: 'UniversitasEvaluasiDokter', params: { praktik_id: item.id } }">
					<v-list-item-title>Evaluasi Peserta Didik</v-list-item-title>
				</router-link>
				<router-link v-else-if="item.profesi == 'PPDS'"
					:to="{ name: 'UniversitasEvaluasiPPDS', params: { praktik_id: item.id } }">
					<v-list-item-title>Evaluasi Peserta Didik</v-list-item-title>
				</router-link>
				<router-link v-else-if="item.profesi == 'Keperawatan,Kebidanan, dan Tenaga Kesehatan Lain'"
					:to="{ name: 'UniversitasEvaluasiLain', params: { praktik_id: item.id } }">
					<v-list-item-title>Evaluasi Peserta Didik</v-list-item-title>
				</router-link>
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="getDataFromApi">
					Reload
				</v-btn>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil } from '@mdi/js'

export default {
	components: {
		// UploadDokumenRegistrasi,
	},
	data: () => ({
		icons: {
			mdiEye,
			mdiPencil,
		},
		tableOptions: {},
		table: {
			loading: true,
			headers: [
				{
					text: 'No',
					align: 'start',
					sortable: false,
					value: 'no',
				},
				{ text: 'Institusi/ Prodi', value: 'institusi' },
				{ text: 'Kelompok', value: 'profesi' },
				{ text: 'Stase/Prodi', value: 'stase' },
				{ text: 'Tanggal Awal', value: 'tanggal_awal' },
				{ text: 'Tanggal Akhir', value: 'tanggal_akhir' },
				{ text: 'Status', value: 'flag_end' },
				{ text: 'Aksi', value: 'actions' },
			],
			items: [],
			totalItems: 0,
		},
		dialog: false,
		// dialogDelete: false,
		form: {
			editedIndex: -1,
			editedItem: {
				id_institusi: '',
				id_prodi: '',
				nama: '',
				profesi: '',
				stase_id: 0,
				tanggal_awal: '',
				tanggal_akhir: '',
			},
			defaultItem: {
				id_prodi: '',
				id_prodi: '',
				nama: '',
				profesi: '',
				stase_id: 0,
				tanggal_awal: '',
				tanggal_akhir: '',
			},
			profesis: [
				'Dokter',
				'PPDS',
				'Keperawatan,Kebidanan, dan Tenaga Kesehatan Lain',
			],
			stases: [],
		},
		institusi_pendidikans: [],
		prodis: [],
	}),
	watch: {
		tableOptions: {
			handler() {
				this.getDataFromApi()
			},
			deep: true,
		},
	},
	computed: {
		formTitle() {
			return this.form.editedIndex === -1 ? 'Tambah Item' : 'Edit Item'
		},
		user() {
			return this.$store.getters.user
		},
	},
	created() {
		this.getStase()
		this.getInstitusiPendidikan()
		this.getProdi()
	},
	methods: {
		getInstitusiPendidikan() {
			axiosGetAuth('api/RegisterLanjutan/getInstitusiPendidikans')
				.then(response => {
					if (response.code === 200) {
						this.institusi_pendidikans = response.datas
					} else {
						alert(response.message)
					}
				})
				.catch(error => {
					alert(error)
				})
		},
		getProdi() {
			axiosGetAuth('api/RegisterLanjutan/getProdis')
				.then(response => {
					if (response.code === 200) {
						this.prodis = response.datas
					} else {
						alert(response.message)
					}
				})
				.catch(error => {
					alert(error)
				})
		},
		getStase() {
			axiosGetAuth('api/Praktik/getStase')
				.then(response => {
					this.form.stases = response.data
				})
				.catch(error => {
					alert(error)
				})
		},
		save() {
			if (this.form.editedIndex > -1) {
				const fmData = new FormData()
				fmData.append('id', this.form.editedItem.id)
				fmData.append('nama', this.form.editedItem.nama)
				fmData.append('id_institusi', this.form.editedItem.id_institusi)
				fmData.append('id_prodi', this.form.editedItem.id_prodi)
				fmData.append('profesi', this.form.editedItem.profesi)
				fmData.append('stase_id', this.form.editedItem.stase_id)
				fmData.append('tanggal_awal', this.form.editedItem.tanggal_awal)
				fmData.append('tanggal_akhir', this.form.editedItem.tanggal_akhir)
				axiosPostAuth('api/Praktik/updatePraktik', fmData)
					.then(response => {
						if (response.code === 200) {
							alert(response.message)
							this.dialog = false
							this.getDataFromApi()
						}
						else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			} else {
				const fmData = new FormData()
				fmData.append('nama', this.form.editedItem.nama)
				fmData.append('id_institusi', this.form.editedItem.id_institusi)
				fmData.append('id_prodi', this.form.editedItem.id_prodi)
				fmData.append('profesi', this.form.editedItem.profesi)
				fmData.append('stase_id', this.form.editedItem.stase_id)
				fmData.append('tanggal_awal', this.form.editedItem.tanggal_awal)
				fmData.append('tanggal_akhir', this.form.editedItem.tanggal_akhir)
				axiosPostAuth('api/Praktik/addPraktik', fmData)
					.then(response => {
						if (response.code === 200) {
							this.getDataFromApi()
						}
						else {
							alert(response.message)
						}
					})
					.catch(error => {
						alert(error)
					})
			}
		},
		getDataFromApi() {
			const { page, itemsPerPage } = this.tableOptions
			const fmData = new FormData()
			fmData.append('page', page)
			fmData.append('itemsPerPage', itemsPerPage)
			fmData.append('id_institusi', this.user.id_institusi)
			fmData.append('search', '')
			axiosPostAuth('api/Praktik/getPraktik_datatable', fmData)
				.then(response => {
					this.table.items = response.data
					this.table.totalItems = response.total
					this.table.loading = false
				})
				.catch(error => {
					alert(error)
				})
		},
		add() {
			this.form.editedItem = Object.assign({}, this.form.defaultItem)
			this.form.editedIndex = -1
		},
		// editItem(item) {
		// 	this.form.editedIndex = this.table.items.indexOf(item)
		// 	this.form.editedItem = Object.assign({}, item)
		// 	this.dialog = true
		// },
		switchKeaktifan(flag_end) {
			let conf = true
			if (flag_end == 0) {
				conf = confirm("Apakah yakin akan menonaktifkan?")
			} else {
				conf = confirm("Apakah yakin akan mengaktifkan?")
			}
			if (conf) {
				const fmData = new FormData()
				fmData.append('id', this.form.editedItem.id)
				fmData.append('flag_end', this.form.editedItem.flag_end)
				axiosPostAuth('api/Praktik/updateStatus', fmData)
					.then(response => {
						this.getDataFromApi()
						this.dialog = false
					})
					.catch(error => {
						alert(error)
					})
			}
		},
	},
}
</script>